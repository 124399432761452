<template>
  <div>
    <div v-show="showIndex==0">
      <div class="align-items-center justify-content-center mx-1 my-3" style="background-color: white; color: #828c9c; height: auto;">
        <CRow>
          <CCol sm="6" class="align-items-center justify-content-center">
            <CRow>
              <CCol sm="12" xl="12">
                <b>{{`${$t('label.inspections')} / ${$t('label.Seals')}`}}</b>
              </CCol>
            </CRow>
          </CCol>
          <CCol sm="6" v-if="ValidateTpEirTransacId">
            <CRow>
              <CCol sm="12" xl="12" class="align-items-center">
                <CButton
                  shape="square"
                  color="add"
                  class="d-flex align-items-center ml-auto"
                  v-c-tooltip="{
                    content: $t('label.inspection'),
                    placement: 'top-end'
                  }"
                  @click="showIndex=1"
                >
                  <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
                </CButton>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </div>
      <div>
        <CRow>
            <CCol col="12" xl="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    details
                    column-filter
                    :items="formatedItems"
                    :fields="fields"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="tableText.itemsPerPage"
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                >
                    <template #Status="{item}">
                      <td class="text-center">
                        <CBadge :color="getBadge(item.Status)">{{item.Status}}</CBadge>
                      </td>
                    </template>
                    <template #button="{item}">
                        <td class="text-center">
                            <CButton
                              color="edit"
                              square
                              size="sm"
                              class="mr-1"
                              v-c-tooltip="{
                                content: `${$t('label.edit')} ${$t('label.Seals')}`,
                                placement: 'top-start'
                              }"
                              @click="toggleAdd(item)"
                            >
                              <CIcon name="pencil" />
                            </CButton>
                            <CButton
                              v-if="viewBottom(item)"
                              color="watch"
                              square
                              size="sm"
                              v-c-tooltip="{
                                content: `${$t('label.images')}`,
                                placement: 'top-start'
                              }"
                              @click="ViewImages(item)"
                            >
                              <CIcon name="eye"/>
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
      </div>
    </div>
    <div v-show="showIndex==1">
      <SealRegister
          :Active="showIndex==1"
          :isEdit="isEdit"
          :SealItem="SealItem"
          @Close="(showIndex=0, SealItem={}, isEdit=false)"
          @Update-list="UpdateData"
      />
    </div>
    <ModalImagesList
      :modal="ModalImages"
      :Images="ImagesList"
      :titleModal="ImagenModalTitle"
      @Close="ModalImages=false,ImagesList=[],ImagenModalTitle=''"
    />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';
import SealRegister from './seal-register';
import ModalImagesList from './modal-images-list';

//DATA-------------------------
function data() {
  return {
    showIndex: 0,
    SealItem:{},
    SealList :[],
    isEdit: false,
    ModalImages: false,
    ImagesList: [],
    ImagenModalTitle: '',
  }
}

//methods
async function UpdateData() {
  this.$store.state.yardManagement.loading = true;
  await this.getYardCargo();
  await this.getYardCargoInspections();
}

function ViewImages(item) {
  try {
    let images = [];

    this.ImagenModalTitle =this.$t('label.inspectionSeals') ?? '';
    
    item.SealJson.map((seal)=>{

      seal.Seal1Json.map((seal1)=>{
        seal1.SealDocumentJson.map((document)=>{
          images.push({...document, seal: '1'});
        })
      });

      seal.Seal2Json.map((seal2)=>{
        seal2.SealDocumentJson.map((document)=>{
          images.push({...document, seal: '2'});
        })
      });

      seal.Seal3Json.map((seal3)=>{
        seal3.SealDocumentJson.map((document)=>{
          images.push({...document, seal: '3'});
        })
      });

      seal.Seal4Json.map((seal4)=>{
        seal4.SealDocumentJson.map((document)=>{
          images.push({...document, seal: '4'});
        })
      });

    });

    this.ImagesList = images;

    if(this.ImagesList.length>0)
      this.ModalImages = true;
    else
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: this.$t('label.noImages'),
        type: "error"
      });

  } catch (error) {}
}

async function getYardCargoInspections() {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardCargoInspections-by-CargoId', { CargoId: this.dataContainer.CargoId})
    .then(response => {
      this.SealList = response.data.data ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
        this.$store.state.yardManagement.loading = false;
    });
}

async function getYardCargo() {
  await this.$http.get('YardCargo-by-Id', { YardCargoId: this.YardCargoId, YardId: this.YardId })
    .then(response => {
      let List = response.data.data;
      if (List&&List.length!=0) {
        if(this.$store.state.yardManagement.dataContainer.YarCargoDispachtId){
          let yardCargoReception = '', yardCargoDispatch = ''; 

          yardCargoReception = this.$store.state.yardManagement.dataContainer.YardCargoId;
          yardCargoDispatch = this.$store.state.yardManagement.dataContainer.YarCargoDispachtId;

          this.$store.state.yardManagement.dataContainer = {...List[0], YardCargoId : yardCargoReception, YarCargoDispachtId: yardCargoDispatch};
        } 
        else{
          this.$store.state.yardManagement.dataContainer = List[0];
        }
        
        if (process.env.VUE_APP_YARD_CARGO_STATUS_RECEPTION == List[0].YardCargoStatus.toUpperCase()) {
          this.$store.state.yardManagement.globalSelectContainer = 
            this.$store.state.yardManagement.globalSelectContainer.map(Select=>{
              return Select.CargoId == this.CargoId ? List[0] : Select;
            });
        }
      }else{
        this.$store.state.yardManagement.dataContainer = {};
        this.$store.state.yardManagement.ListReceivedContainers = [];
        this.$store.state.yardManagement.ListDispatchedContainers = [];
        this.$store.state.yardManagement.ListRecognitionContainers = [];
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
        this.$store.state.yardManagement.ListReceivedContainers = [];
        this.$store.state.yardManagement.ListDispatchedContainers = [];
        this.$store.state.yardManagement.ListRecognitionContainers = [];
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function toggleAdd(item) {
  this.SealItem = item;
  this.isEdit = true;
  this.showIndex = 1;
}

//computed
function fields(){
  return [
    {
      key: 'button', 
      label: '', 
      _style: 'min-width: 90px; width: 1%;', 
      sorter: false, 
      filter: false
    },
    { key: 'Seal',label: this.$t('label.Seals'), _style:'width: 27%;', _classes: 'text-center',},
    { key: 'EventName',label: this.$t('label.Event'), _style:'width: 16%;', _classes: 'text-center',},
    { key: 'DeviceCode',label: this.$t('label.device'), _style:'width: 16%;', _classes: 'text-center',},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width: 13%;', _classes: 'text-uppercase text-center' },
    { key: 'ComputedTransactionDate', label: this.$t('label.date'),  _style:'width: 13%', _classes:"text-center" },
    { key: 'Status', label: this.$t('label.status'),  _style:'width: 10%', _classes:"text-center" },
  ];
}

function formatedItems() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.SealList.map((item) => {
    let SealJson = item?.SealJson[0] ?? '';
    let SealArray = SealJson ? SealJson?.Seal1Json.concat(SealJson?.Seal2Json, SealJson?.Seal3Json, SealJson?.Seal4Json) : [];
    let Seal = SealArray.map((SealArray, SealIndex) => {
      if (SealArray.Seal) {
        return `S${SealIndex+1}: ${SealArray.Seal.trim()}`
      }
    }).join(', ');
    return {
      ...item,
      Nro: item.IdX,
      Seal: Seal,
      EventName: item[`EventName${_lang}`] ?? '',
      ComputedTransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
      TransaLogin: item.TransaLogin ? item.TransaLogin : '',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
    };
  })
}

function viewBottom(item) {

  let images = [];

    
    item?.SealJson?.map((seal)=>{

      seal?.Seal1Json?.map((seal1)=>{
        seal1?.SealDocumentJson?.map((document)=>{
          images.push({...document, seal: '1'});
        })
      });

      seal?.Seal2Json?.map((seal2)=>{
        seal2?.SealDocumentJson?.map((document)=>{
          images.push({...document, seal: '2'});
        })
      });

      seal?.Seal3Json?.map((seal3)=>{
        seal3?.SealDocumentJson?.map((document)=>{
          images.push({...document, seal: '3'});
        })
      });

      seal?.Seal4Json?.map((seal4)=>{
        seal4?.SealDocumentJson?.map((document)=>{
          images.push({...document, seal: '4'});
        })
      });

    });

    return images.length>0 ? true : false;
}

function ValidateTpEirTransacId() {
  return process.env.VUE_APP_TP_EIR_TRANSAC_ID==this.TpEirTransacId?.toUpperCase();
}

export default {
  name: 'seal-index',
  components: {
    SealRegister,
    ModalImagesList
   },
  data,
  mixins: [GeneralMixin],
  methods: {
    toggleAdd,
    getYardCargoInspections,
    getYardCargo,
    UpdateData,
    ViewImages,
    viewBottom,
  },
  computed: {
    fields,
    formatedItems,
    ValidateTpEirTransacId,
    ...mapState({
        CargoId: state => state.yardManagement.dataContainer.CargoId,
        YardCargoId: state => state.yardManagement.dataContainer.YardCargoId,
        TpEirTransacId: state => state.yardManagement.dataContainer.TpEirTransacId,
        YardId: state => state.yardManagement.yardData.YardId,
        GeneralList: state => state.yardManagement.GeneralList,
        dropItemInspection: state => state.yardManagement.dropItemInspection,
        dataContainer: state => state.yardManagement.dataContainer,
    })
  },
  watch: {
    dropItemInspection: function (val) {
      if(val == 2){
        this.showIndex = 0;
        this.getYardCargoInspections();
      }
    },
  },
};
</script>